export function getHashParameters() {
    const hashParams: any = {};
    try {
        window.location.hash
            .substring(1)
            .split('&')
            .forEach(function (item) {
                const s = item.split('=');
                const k = s[0];
                const v = s[1] && decodeURIComponent(s[1]);
                hashParams[k] = v;
            });
        return hashParams;
    } catch (error) {
        console.log(error);
    }
    return null;
}