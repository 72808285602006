export function getPdfPath(string: string) {
    // Split the string using the "/" delimiter
    const parts = string.split("/");
  
    // Find the index of the part containing ".pdf"
    const pdfIndex = parts.findIndex(part => part.includes(".pdf"));
  
    // Extract the PDF filename if it exists
    return pdfIndex !== -1 ? parts[pdfIndex] : null;
}

export function getSourcePath(string: string) {
    const parts = string.split("?");
    const fileName = parts[0].split("/").slice(-1)[0];

    return fileName;
}

export function getJsonPath(string: string) {
    // Split the string using the "/" delimiter
    const parts = string.split("/");
  
    // Find the index of the part containing ".pdf"
    const jsonIndex = parts.findIndex(part => part.includes(".json"));
  
    // Extract the PDF filename if it exists
    return jsonIndex !== -1 ? parts[jsonIndex] : null;
}

export function getDistinctFileOccurrences(text: string) {
    const matches = text.match(/\[File(\d+)\]/g);
    
    if (!matches) return [];

    const files = new Set();

    for (const match of matches) {
        files.add(match.replace(/\[|\]/g, ""));
    }
    
    return Array.from(files);
}